<template>
  <div class="flex justify-center items-center mt-10">
    <div class="bg-white p-2 rounded-lg w-6/12 shadow-sm">
      <div class="text-center my-4">
        <h2 class="text-lg font-bold">
          Actualizar Impugnación
        </h2>
      </div>
      <div class="flex flex-wrap content-around">
        <div class="w-full flex flex-col items-center py-5 px-2 ">
          <div class="px-2 w-full flex flex-col gap-4">

            <div class="flex flex-col justify-center">
              <label class="font-medium text-sm">Nombre:</label>
              <InputText v-model="model.nombre"/>
              <MessageError :text="errors.nombre"/>
            </div>
            <div class="flex flex-col justify-center">
              <label class="font-medium text-sm">Tipo y numero de documento:</label>
              <div class="flex gap-2">
                <Dropdown v-model="model.tipoDoc"
                          :options="docTipos"
                          optionLabel="code"
                          optionValue="code"
                          class="w-2/6"
                />
                <InputText v-model="model.numeroIdentificacion" class="w-4/6"/>
              </div>
              <MessageError :text="errors.numeroIdentificacion"/>
              <MessageError :text="errors.tipoIdentificacion"/>
            </div>
            <div class="flex flex-col justify-center">
              <label class="font-medium text-sm">Fecha de Impugnación:</label>
              <input class="p-inputtext w-full"
                     type="date"
                     v-model="model.fechaImpugnacion"
              />
              <MessageError :text="errors.fechaImpugnacion"/>
            </div>
            <div class="flex flex-col col-span-3">
              <label class="font-medium text-sm">Observaciones</label>
              <Textarea
                v-model="model.observaciones"
                placeholder="Observaciones"
                class="w-full"/>
              <MessageError :text="errors.observaciones"/>
            </div>

            <div class="px-2 w-full">
              <div style="max-height: 150px; overflow-y: auto;">
                <div class="flex justify-between" v-for="(item, key) in adjuntos" :key="key">
                  <div class="flex mt-2">
                    <i class="pi pi-file-pdf mr-2"></i>
                    <p>{{ item.fileName }}</p>
                  </div>
                  <div>
                    <button type="button" @click="verPdf(item.urlPath)"><i class="pi pi-eye mr-2 text-blue-600"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full mb-4 flex flex-col">
              <button label="Show" icon="pi pi-external-link" @click="openMaximizable"
                      class="w-full rounded-full py-2 bg-white border border-solid border-gray-400">
                Agregar nuevo soporte
              </button>
              <Dialog header="Agregar soportes" v-model:visible="displayMaximizable"
                      :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
                <div>
                  <div>
                    <label class="text-sm" for="titulo">Titulo</label>
                    <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
                           class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  </div>
                  <div class="my-2">
                    <label class="text-sm">Adjunte el documento</label>
                    <div class="mt-2">
                      <button type="button" class="w-full">
                        <FilePond ref="pond"/>
                      </button>
                    </div>
                  </div>
                </div>
                <template class="text-center" #footer>
                  <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus/>
                </template>
              </Dialog>
            </div>
          </div>
        </div>
        <div class="w-full flex justify-between px-2 mb-9">
          <Button label="Cancelar"
                  class="bg-blue-900 p-button-outlined"
                  style="width: 244px;"
                  @click="$router.go(-1)"
          />
          <Button label="Actualizar"
                  class="bg-blue-900"
                  style="width: 244px;"
                  type="button"
                  @click="handleNext"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storeImpugnaciones from '../../../../store/impugnaciones.store'
import storeFallos from '@/apps/pharmasan/juridica/procesosJuridicos/store/fallos.store'
import filepondStore from '../../../../store/filepond.store'
import FilePond from '../../../uploadFilePoundNew/index.vue'
import { onMounted, ref, computed } from 'vue'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'
import DocTipoService from '@/apps/pharmasan/juridica/procesosJuridicos/services/docTipo.service'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'

export default {
  name: 'actualizarImpugnacion',
  components: {
    FilePond
  },
  setup () {
    /** computed **/
      // const impugnacion = computed(() => {
      //   return storeImpugnaciones.getters.impugnacion
      // })
    const fallos = computed(
        () => storeFallos.getters.fallos.rows
      )
    const adjunto = computed(() => filepondStore.getters.adjunto)

    /** intancias **/

    const route = useRoute()
    const router = useRouter()
    const tiposProcesos = ref([])
    const dataDropdown = ref([])
    const displayMaximizable = ref(false)
    const adjuntos = ref([])
    const pond = ref()
    const title = ref('')
    const falloSelected = ref()
    const docTipos = ref([])
    const _DocTipoService = new DocTipoService()
    const _PdfService = new PdfService()
    const id = ref(null)

    /** state **/
    const schema = yup.object({
      nombre: yup.string().nullable().required('El campo es requerido').label(''),
      tipoDoc: yup.string().nullable().required('El campo es requerido').label(''),
      numeroIdentificacion: yup.string().nullable().required('El campo es requerido').label(''),
      fechaImpugnacion: yup.date('No es una fecha permitido').nullable().required('El campo es requerido').label(''),
      observaciones: yup.string().nullable().required('El campo es requerido').label('')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('nombre')
    useField('tipoDoc')
    useField('numeroIdentificacion')
    useField('fechaImpugnacion')
    useField('observaciones')
    /** data **/

    /** methodos **/
    const falloValor = () => {
      model.base = falloSelected.value.id
    }
    const handleNext = handleSubmit(async (values) => {
      if (!values) return console.error('Error => validation')
      values.id = route.params.id
      storeImpugnaciones.dispatch('updateImpugnacion', values).then(() => {
        Swal.fire({
          title: 'Exito',
          text: 'Impugnación actualizado con exito',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            router.go(-1)
          }
        })
      })
    })

    const openMaximizable = () => {
      displayMaximizable.value = true
    }

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        adjuntos.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        storeImpugnaciones.dispatch('updateImpugnacion', {
          id: id.value,
          paths: adjuntos.value
        }).then(() => {
          Swal.fire({
            title: 'Exito',
            text: 'Impugnación actualizado con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              storeFallos.dispatch('findImpugnacion', id.value)
            }
          })
        })
        displayMaximizable.value = false
        title.value = ''
      })
    }

    /** hooks **/
    onMounted(() => {
      id.value = route.params.id
      _DocTipoService.getAll().then(({ data }) => {
        docTipos.value = data
      })
      storeImpugnaciones.dispatch('findImpugnacion', route.params.id).then((data) => {
        storeFallos.dispatch('getPaginate').then(() => {
          dataDropdown.value = fallos.value
          falloSelected.value = fallos.value.find(fallo => fallo.id === data.base)
        })
        model.nombre = data.nombre
        model.tipoDoc = data.tipoDoc
        model.numeroIdentificacion = data.numeroIdentificacion
        model.fechaImpugnacion = dayjs(data.fechaImpugnacion).format('YYYY-MM-DD')
        model.observaciones = data.observaciones
        adjuntos.value = data.paths
      })
    })

    return {
      errors,
      model,
      handleNext,
      tiposProcesos,
      dataDropdown,
      displayMaximizable,
      openMaximizable,
      closeMaximizable,
      title,
      adjunto,
      adjuntos,
      pond,
      falloSelected,
      falloValor,
      docTipos,
      verPdf
    }
  }
}
</script>

<style lang="scss">
.p-message .p-message-wrapper {
  padding: 0.5rem 1.5rem;
}
</style>
