import { createStore } from 'vuex'
import ImpugnacionesService from '../services/impugnaciones.service'
const _ImpugnacionesService = new ImpugnacionesService()

const storeImpugnaciones = {
  state: {
    _impugnaciones: [],
    _impugnacion: {}
  },
  getters: {
    impugnaciones: state => state._impugnaciones,
    impugnacion: state => state._impugnacion
  },
  mutations: {
    setImpugnaciones (state, payload) {
      state._impugnaciones = payload
    },
    setImpugnacion (state, payload) {
      state._impugnacion = payload
    }
  },
  actions: {
    getPaginate ({ commit }, payload) {
      return _ImpugnacionesService.paginate(payload).then(({ data }) => {
        commit('setImpugnaciones', data)
      })
    },
    createImpugnacion ({ commit }, payload) {
        return _ImpugnacionesService.save(payload).then((response) => {
            return response
        })
    },
    findImpugnacion ({ commit }, id) {
      return _ImpugnacionesService.find(id).then(({ data }) => {
        commit('setImpugnacion', data)
        return data
      })
    },
    updateImpugnacion ({ getters }, payload) {
      const { id } = payload
      delete payload.id
      return _ImpugnacionesService.edit(payload, id).then(({ data }) => {
        return data
      })
    },
    removeImpugnacion ({ commit }, id) {
      return _ImpugnacionesService.remove(id).then((response) => {
        return response
      })
    }
  }
}
const store = createStore(storeImpugnaciones)
export default {
  namespaced: true,
  ...store
}
